

import { mdiCheck, mdiInformationOutline } from '@mdi/js'

export default {
    name: 'CheckerResults',
    props: {
        results: {
            type: Array,
            required: false,
            default: () => [
                {
                    tool: 'GPT-Zero',
                    human_score: 1.0,
                    link: 'https://gptzero.me/'
                },
                {
                    tool: 'ZeroGPT',
                    human_score: 1.0,
                    link: 'https://zerogpt.com/'
                },
                {
                    tool: 'Winston.ai',
                    human_score: 1.0,
                    link: 'https://gowinston.ai/'
                },
                {
                    tool: 'Copyleaks',
                    human_score: 1.0,
                    link: 'https://copyleaks.com/'
                }
            ]
        }
    },
    data: () => ({
        mdiCheck,
        mdiInformationOutline
    }),
    methods: {
        formatScore(score) {
            return Math.round((score * 100))
        }
    }
}
